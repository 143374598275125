import React, { FC, useContext, useEffect } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink, useHistory } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-1.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from "@mui/material/Rating";
import ModalQuickView from "components/ModalQuote";
import AuthContext from "Context/AuthContext";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import LikeButton from "components/LikeButton";
import ModalQuickView2 from "components/ModalAd";
import WishContext from "Context/WishContext";
import _ from "lodash";
import { AppliedJobs } from "Services/API/Get";
import { t } from "i18next";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  collection?: any;
  data?: any;
  selectedAdds?: any;
  setselectedAdds?: any;
  type?: any;
  form?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  collection,
  data,
  selectedAdds,
  setselectedAdds,
  type,
  form = false
}) => {
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const [showModalQuickView2, setShowModalQuickView2] = React.useState(false);

  const router = useHistory();
  const { user, setUser } = useContext(AuthContext);

  const { applied, setapplied } = useContext(WishContext);

  useEffect(() => {
    console.log(">>>>applied", applied);
  }, [applied]);

  useEffect(() => {
    AppliedJobs({
      jobType: JSON.stringify([])
    })
      .then((res) => {
        console.log(">>>>>>>jobs", res.data);
        setapplied(res.data.result);
      })
      .catch((error) => {
        // setlopen(false);
        setapplied([]);
        console.log("Error", error.response);

        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      });
  }, [showModalQuickView, showModalQuickView2]);

  const getState = (item?: any) => {
    if (item === "Victoria") {
      return "VIC";
    } else if (item === "New South Wales") {
      return "NSW";
    } else if (item === "Western Australia") {
      return "WA";
    } else if (item === "Queensland") {
      return "QLD";
    } else if (item === "Tasmania") {
      return "TAS";
    } else if (item === "South Australia") {
      return "SA";
    } else if (item === "Northern Territory") {
      return "NT";
    } else if (item === "Australian Capital Territory") {
      return "ACT";
    }
  };

  const getTime = (value: any) => {
    let text: any = "";

    if (value > 525600) {
      value = Math.floor(value / 525600);

      return `${value} years ago`;
    } else if (value > 43800) {
      value = Math.floor(value / 43800);

      return `${value} months ago`;
    } else if (value > 10080) {
      value = Math.floor(value / 10080);

      return `${value} weeks ago`;
    } else if (value > 1440) {
      value = Math.floor(value / 1440);

      return `${value} days ago`;
    } else if (value > 60) {
      value = Math.floor(value / 60);

      return `${value} hours ago`;
    } else if (value > 0) {
      return `${value} minutes ago`;
    } else if (value) {
      value = Math.floor(value * 60);

      return `${value} secs ago`;
    } else {
      return "secs ago";
    }
  };

  console.log(data)
  return (
    <>
      {/* <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        jobId={data}
        type={"job"}
        setselectedAdds={setselectedAdds}
      /> */}

      <ModalQuickView
        show={showModalQuickView2}
        onCloseModalQuickView={() => setShowModalQuickView2(false)}
        jobId={data}
        type={"job"}
        setselectedAdds={setselectedAdds}
      />

      <ModalQuickView2
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        jobId={data}
        type={"job"}
        setselectedAdds={setselectedAdds}
        form={form}
      />

      <div
        className={`nc-CardCategory2 ${className} h-full`}
        data-nc-id="CardCategory2"
      >
        {/* {
          type === "shop" &&

          <div className="">
            <input id="checkbox-switch-2" className="ml-0 mb-3 w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer" type="checkbox"
              checked={selectedAdds?.includes(data?.id)}
              onChange={(checked) => {

                console.log("<<<<<", data.id)

                if (selectedAdds?.includes(data?.id)) {
                  const index = selectedAdds.indexOf(data?.id);

                  console.log(">>>>>>index", index)

                  var arr = [...selectedAdds]
                  if (index > -1) {
                    arr.splice(index, 1);
                    console.log("arr>>>>", arr)
                    setselectedAdds(arr)
                  }

                } else {
                  setselectedAdds(((item: any) => [...item, data?.id]))
                }
                // setSelectedcat(data?.id);


              }}
            />
          </div>
        } */}

        <div
        // className={`flex-1 relative w-full h-fit rounded-2xl group`}
        >
          {/* <div
            className={`
          //shadow-[0px_5px_10px_5px_rgba(0,0,0,0.3)] 
          //shadow-slate-200
          bg-[#F4F4F4]
          rounded-lg relative h-full
          ${data?.priority === "a" && "border border-[#2d867b]"}
          `}
          > */}
          <div
            className={`
          //shadow-[0px_5px_10px_5px_rgba(0,0,0,0.3)] 
          //shadow-slate-200
          bg-[#F4F4F4]
          rounded-lg relative h-full
          ${data?.priority ===  "a" && "border border-[#2d867b]"}
          `}
          >
            {/* <LikeButton
              // liked={_.map(wish, "id").includes(id)} 
              className="absolute top-2 right-2 z-10"
            //  addWish={notifyAddToWish} 
            /> */}

            <div className="flex justify-end w-full h-full">
              {/* <i className="las la-bookmark text-4xl text-left mr-1 mt-2 "></i> */}
              {/* <img className="text-4xl text-left mr-1 mt-2 cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA00lEQVR4nO2VMU4CQRiFNzQmlhQewIYj2HgBQmPnMSw5Btew4w5WHoFslnnfm2ULCkoTG1lDgYUJgR0WtJgv+bv38iWTmX+KIvMfACZIK0PbZZBqSeN0sVR3lf7IISaLnSjdTxafTD5q58tFfk7kBZKG867mj38npK3tqe0XpK9riT8dwvM+E6UnpI+LipE2th9/5+oQHgzry4ilZQhhdDBr3xsWvYqB96qq7o7lY4xDpLdexMC8aZrbUztlWd4Ar2eJgVnbtoOuvV1n100WZ4oe+QZXC8i3JwqA3AAAAABJRU5ErkJggg==" /> */}
              {/* <div className="px-4 bg-[#FF4848] rounded-xl mr-2 mt-4 flex items-center py-1">
                <span className="text-xs text-slate-100">Featured</span>
              </div> */}
            </div>
            <div className="flex justify-between w-full h-4">
              <div className="flex">
                {
                  // data?.priority !== "z" ?
                  false ? (
                    <button
                      className={`ttnc-ButtonCircle flex items-center justify-center !leading-none disabled:bg-opacity-70 
font-medium cursor-pointer  bg-[#2d867b] px-2.5 lg:px-4 h-5 rounded-tl-[5px]`}
                    >
                      <span className="block  text-[7px] lg:text-[9px] font-medium whitespace-nowrap text-white">
                        Sponsored
                      </span>
                    </button>
                  ) : (
                    // <LikeButton
                    //   // liked={_.map(wish, "id").includes(id)}
                    //   className=""
                    // //  addWish={notifyAddToWish}
                    // />

                    <></>
                  )
                }
              </div>
            </div>
            <div
              className={`${collection ? "px-3 sm:px-5" : "px-7"} pb-2 h-full`}
            >
              {/* <NavLink to={`/job-single/${data?.id}`} > */}

              {/* <div className="flex" >
                  <img className="w-14 h-14 aspect-square object-fill bg-cover rounded-md"

                    // src={Company1} 
                    src={data ? `${IMAGE_SERVER_URL_DEV}${data?.avatar}` : Company1}
                  />


                </div> */}

              <div className="mt-0">
                {/* <div className="flex justify-end h-5">



                  {
                    data?.priority === "a" ?
                      <button
                        className={
                          `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
font-medium cursor-pointer  bg-[#b69d52] px-2.5 lg:px-4 h-5`}



                      >

                        <span className="block  text-[7px] lg:text-[9px] font-medium whitespace-nowrap text-white">
                          Sponsored
                        </span>

                      </button>

                      :


                      <></>


                  }


                </div> */}

                <div className="flex justify-between w-full mt-0">
                  <div className="flex flex-col">
                    <h2
                    // className="text-xs sm:text-xs text-slate-900 dark:text-neutral-100 font-bold h-6"
                    >
                      {/* {
                        data ? data?.name?.slice(0, 21) : "Company description"
                      }

                      {
                        data?.name?.length >= 21 ? "..." : ""
                      } */}

                      {/* {
    data ? data?.title : "Ad Title should be here appear here "
  } */}

                      {/* {data?.gender === "Male" ? "Looking for Bride" : (data?.gender === "Female" ? "Looking for Groom" : (t("langKey") === "Si" && data?.gender === "සහකරු" ? "මනාලියෙකු සොයයි": "මනමාලයෙකු සොයයි"))} */}
                    </h2>

                    <span
                      className={`text-slate-900 flex items-center justify-start ${
                        t("langKey") === "Si" ? "text-[8pt]" : "text-[8pt]"
                      }  -ml-1`}
                    >
                      {/* <i className="las la-eye text-lg text-left mr-1"></i>
                     
                      {data?.viewsCount} */}
                      <i
                        className={`las la-map-marker-alt text-lg text-left mr-1`}
                      ></i>
                      {data?.currentResidesCountry === "Sri Lanka" ? (
                        <>
                          {data?.city} {getState(data?.district)}
                        </>
                      ) : (
                        <>{data?.currentResidesCountry}</>
                      )}
                    </span>
                  </div>

                  {data?.priority === "a"  && (
                    <button
                      className={`ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
font-medium cursor-pointer  bg-[#bd8d01] px-2.5 lg:px-4 h-5`}
                    >
                      <span className="block  text-[7px] lg:text-[9px] font-medium whitespace-nowrap text-white">
                        Sponsored
                      </span>
                    </button>
                  )}

                  {/* <div className="flex">



                    {
                      _.map(applied, "adId").includes(data?.id) === true ?
                        <button
                          className={
                            `ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70 
         font-medium cursor-pointer  bg-[#d63a3a] px-2.5 lg:px-4 h-5`}



                        >

                          <span className="block  text-[7px] lg:text-[9px] font-medium whitespace-nowrap text-white">
                            Applied
                          </span>

                        </button>

                        :

                        // <LikeButton
                        //   // liked={_.map(wish, "id").includes(id)} 
                        //   className=""
                        // //  addWish={notifyAddToWish} 
                        // />

                        <></>


                    }


                  </div> */}
                </div>

                <span
                  className={`flex flex-col justify-start ${
                    collection ? "text-[11px]" : "text-xs"
                  } text-neutral-500 dark:text-neutral-400 mt-2 `}
                >
                  {/* <span className="text-slate-900 flex items-center justify-start">
                     
                      <i className="las la-map-marker-alt text-lg text-left mr-1"></i>
                      {data?.city} {getState(data?.district)}
                    </span> */}

                  {/* <span className="text-slate-900 flex items-center">
                    

                      <i className="las la-clock text-lg text-left mr-1"></i>
                    
                      {getTime(data?.dif)}
                    </span> */}

                  {/* <span className="text-slate-900 flex items-center">
                      <i className="las la-clock text-lg text-left mr-1"></i>
                      {data?.dif} minutes ago
                      {getTime(data?.dif)}
                    </span> */}
                </span>

                {/* <div className={`${data?.description === "" ? "h-12" : "h-7 lg:h-10"}`}>
                    <span className="block mt-3 text-[11.5px] leading-normal font-medium text-slate-900 font-normal">
                      {
                        data ? data?.description?.slice(0, 40) : " Company description - See completed projects and flexible talent strategies from ......"
                      }
                      {
                        data?.description?.length >= 40 ? "..." : ""
                      }
                    </span>
                  </div> */}

                <div
                  className={`text-slate-900 mt-2  ${
                    t("langKey") === "Si"
                      ? "//text-[11.5px] //lg:text-[13.5px] text-[9.5px] lg:text-[10.5px]"
                      : "text-[9.5px] lg:text-[10.5px]"
                  }  font-normal`}
                >
                  {/* <div className="grid grid-cols-2 font-semibold" >

                    <span>{t("fType")}</span>
                    <span className="font-bold "> {data?.gender === "Male" ? "Groom" : (data?.gender === "Female" ? "Bride" : data?.gender)} </span>

                    <span>{t("fAge")}</span>
                    <span className="font-bold "> {data?.age}</span>

                    <span>{t("fHei")}</span>
                    <span className="font-bold "> {data?.height}</span>

                    <span>{t("fOcc")}</span>
                    <span className="font-bold "> {data?.occupation}</span>

                    <span>{t("fCast")}</span>
                    <span className="font-bold"> {data?.cast}</span>



                  </div> */}

                  <div className="flex justify-between">
                    <div className="flex flex-col font-semibold">
                      <span>{t("fType")}</span>
                      <span>{t("fAge")}</span>
                      <span>{t("fHei")}</span>
                      <span>{t("fOcc")}</span>
                      <span>{t("fCast")}</span>
                    </div>

                    <div className="flex flex-col">
                      <span className="font-bold ">
                        {" "}
                        {data?.gender === "Male"
                          ? "Groom"
                          : data?.gender === "Female"
                          ? "Bride"
                          : data?.gender}{" "}
                      </span>
                      <span className="font-bold "> {data?.age}</span>
                      <span className="font-bold "> {data?.height}</span>
                      <span className="font-bold ">
                        {/* {data?.occupation} */}

                        {data
                          ? data?.occupation?.slice(0, 14)
                          : "Company description"}

                        {data?.occupation?.length > 14 ? ".." : ""}
                      </span>
                      <span className="font-bold"> {data?.cast}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* </NavLink> */}

              <div className="mt-6">
                {/* <button
                  className={
                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gray-100 
        w-full h-9 font-medium`}

                >
                  <span className="block  text-xs font-bold">
                    {
                      data ? `${data?.currency} ${data?.hourlyRate}/Hourly` : "$ 250/Hourly"
                    }

                  </span>

                </button> */}

                <button
                  className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#009b88] 
        w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}
                  onClick={() => {
                    // if (!user) {
                    //   router.push("/login");
                    //   return;
                    // }

                    setShowModalQuickView(true);
                  }}
                >
                  {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                  <span className="block  text-[11px] sm:text-xs font-medium">
                    {t("hView")}
                  </span>
                </button>

                {_.map(applied, "adId").includes(data?.id) === false ? (
                  <button
                    className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#002930] 
          w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}
                    onClick={() => {
                      // if (!user) {
                      //   router.push("/login");
                      //   return;
                      // }

                      setShowModalQuickView2(true);
                    }}
                  >
                    {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                    <span className="block  text-[11px] sm:text-xs font-medium">
                      {t("appDetails")}
                    </span>
                  </button>
                ) : (
                  <button
                    className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#d63a3a] 
w-full h-9 text-slate-50 font-medium mt-2 cursor-pointer`}
                  >
                    {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                    <span className="block  text-xs font-medium">
                      Already Applied
                    </span>
                  </button>
                )}

                <div className="flex justify-between text-[6.5pt] sm:text-[7pt] mt-2 items-center">
                  <div className="flex text-gray-500">Ad ID - {data?.id}</div>
                  <div className="flex text-red-500 sm:text-xs">
                    {_.map(applied, "adId").includes(data?.id) === true && (
                      <span>Already Applied</span>
                    )}
                  </div>
                </div>
              </div>

              {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
              {/* <NcImage
            containerClassName="w-full h-full flex justify-center"
            src={featuredImage}
            className="object-cover rounded-2xl"
          /> */}
            </div>
          </div>
          <div className="pt-5"></div>
          {/* <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
        </div>
        {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
      </div>
    </>
  );
};

export default CardCategory2;
